<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>

      <div class="ps-5 page_content grow">
        <div v-if="$store.getters.permission('params access')">
          <v-btn  v-if="$store.getters.permission('params create')" @click="new_param()" outlined color="primary">פרמטר חדש</v-btn>
          <v-card class="mt-3" v-for="(param, i) in items" :key="i">
            <v-form :ref="`form`">
              <v-card-text>
                <Basic
                  :param_index="i"
                  :params="items"
                  @save_param="save($event, i)"
                  :param="param"
                ></Basic>
                <Inputs :params="items" :param_index="i" :param="param" />
              </v-card-text>
            </v-form>
          </v-card>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Basic from "@/components/params/basic";
import Inputs from "@/components/params/inputs";

export default {
  name: "params",
  data() {
    return {
      save_loader: false,
      items: [
        // {
        //   name: "",
        //   type: "input",
        //   value: "",
        //   color: "",
        //   switch: false,
        //   input_type: "text",
        //   save_loader: false,
        //   options: [],
        // },
      ],
    };
  },
  components: {
    Basic,
    Inputs,
  },
  methods: {
    save(param, index) {
      if (!param.save_loader && this.$refs.form[index].validate() && this.$store.getters.permission('create access')) {
        param.save_loader = true;
        const formData = new FormData();
        formData.append("id", param.id || "");
        formData.append("name", param.name);
        formData.append("type", param.type);
        formData.append("value", param.value);
        formData.append("color", param.color);
        formData.append("switch", param.switch);
        formData.append("input_type", param.input_type);
        if (param.type == "option") {
          param.options.forEach((option, i) => {
            formData.append(`options[${i}][image]`, option.image || "");
            formData.append(`options[${i}][value]`, option.value);
            formData.append(`options[${i}][name]`, option.name);
          });
        }

        this.$store
          .dispatch("public__request", {
            config: {
              url: "params",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            
            this.get_params();
          })
          .catch((err) => {
            param.save_loader = false;
            this.get_params();
            // Object.keys(errors).forEach((e) => {
            //   errors[e].forEach((s) => {
            //     if (s.includes("already been taken")) {
            //       this.$set(this, `${e}_exist`, true);
            //       this.$refs.form.validate();
            //     }
            //     if (
            //       s.includes("The photo must be a file of type: jpeg, jpg, png.")
            //     ) {
            //       this.photo_err = true;
            //       this.$refs.form.validate();
            //     }
            //   });
            // });
          });
      }
    },
    new_param() {
      this.items.unshift({
        name: "פרמטר חדש",
        type: "input",
        value: "",
        color: "#000000",
        switch: false,
        input_type: "text",
        save_loader: false,
        options: [],
      });
    },
    get_params() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "params",
            method: "get",
          },
        })
        .then((res) => {
          this.items = Object.assign([], res.data.data);
          
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  mounted() {
    this.get_params();
  },
};
</script>
